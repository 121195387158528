export function useUI() {

  const setBodyScrollable = (scrollable: boolean) => {
    if (scrollable) {
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
    }
    else {
      document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
    }
  }

  const scrollTo = (top: number, onScrolledCallback: Function | undefined) => {
    window.scrollTo({ top: top, behavior: 'smooth' });

    if (typeof onScrolledCallback === 'function') {
      setTimeout(() => onScrolledCallback(), 250);
    }
  }

  const scrollToElement = (element: HTMLElement, offset: number | undefined = 15, onScrolledCallback: Function | undefined) => {
    scrollTo(element.getBoundingClientRect().top + window.scrollY - offset, onScrolledCallback);
  }

  return {
    setBodyScrollable,
    scrollTo,
    scrollToElement
  }
}